window.onload = function () {
    /*720代表设计师给的设计稿的宽度，你的设计稿是多少，就写多少;100代表换算比例，这里写100是
      为了以后好算,比如，你测量的一个宽度是100px,就可以写为1rem,以及1px=0.01rem等等*/
    if (window.location.pathname === '/business/sensorTech' || window.location.pathname === '/business/analyzer') {
        getRemLoadSensor(1515, 100)
    } else {
        getRemLoad(375, 100)
    }
};
window.onresize = function () {
    if (window.location.pathname === '/business/sensorTech' || window.location.pathname === '/business/analyzer') {
        getSensorRem(1515, 100)
    } else {
        getRemLoad(375, 100)
    }
};
function getRem(pwidth, prem) {
    const html = document.getElementsByTagName("html")[0];
    const progress = document.getElementById("progress-group");
    const progressTwo = document.getElementById("progress-group-two");
    const progressThree = document.getElementById("progress-group-three");
    const oWidth = document.body.clientWidth || document.documentElement.clientWidth;
    html.style.fontSize = oWidth / pwidth * prem + "px";
    const devicewidth = document.documentElement.clientWidth;
    progress.style.transform = `scale(${devicewidth / pwidth})`
    progressTwo.style.transform = `scale(${devicewidth / pwidth})`
    progressThree.style.transform = `scale(${devicewidth / pwidth})`
}
function getSensorRem(pwidth, prem) {
    const html = document.getElementsByTagName("html")[0];
    const oWidth = document.body.clientWidth || document.documentElement.clientWidth;
    const progress = document.getElementById('senserSlider')
    const arrs = document.getElementsByClassName('sensor-zoom-word')
    let devicewidth = null
    if (oWidth >= 800) {
        html.style.fontSize = oWidth / pwidth * prem + "px";
        devicewidth = document.documentElement.clientWidth;
    } else {
        html.style.fontSize = 800 / pwidth * prem + "px";
        devicewidth = 800
    }
    progress.style.zoom = devicewidth / pwidth
    for (let i = 0; i < arrs.length; i++) {
        arrs[i].style.zoom = devicewidth / pwidth
    }
}
function getRemLoadSensor(pwidth, prem) {
    const html = document.getElementsByTagName("html")[0];
    const oWidth = document.body.clientWidth || document.documentElement.clientWidth;
    if (oWidth >= 800) {
        html.style.fontSize = oWidth / pwidth * prem + "px";
    } else {
        html.style.fontSize = 800 / pwidth * prem + "px";
    }

}
function getRemLoad(pwidth, prem) {
    const html = document.getElementsByTagName("html")[0];
    const oWidth = document.body.clientWidth || document.documentElement.clientWidth;
    html.style.fontSize = oWidth / pwidth * prem + "px";
}
